<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>Privacy Policy</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="vacancies_area">
            <div class="container">
                Coming Soon
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../_helper/http-constants"
import axios from "axios"
import scrollTop from "./utils/scrollTop"

export default {
    name: "About",

    data() {
        return {
            user: "",

            // imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            // imgsrc: "http://localhost/backend/public/admin_profile/",

            imgsrc: axios.defaults.uploadURL,
        }
    },

    mounted() {
        scrollTop()
        HTTP.get("/api/job").then((res) => {
            this.user = res.data
        })
    },
}
</script>
<style lang="scss" scoped>
/* Font Definitions */
@font-face {
    font-family: "Cambria Math";
    panose-1: 2 4 5 3 5 4 6 3 2 4;
}
@font-face {
    font-family: Calibri;
    panose-1: 2 15 5 2 2 2 4 3 2 4;
}
@font-face {
    font-family: Roboto;
    panose-1: 0 0 0 0 0 0 0 0 0 0;
}
/* Style Definitions */
p.MsoNormal,
li.MsoNormal,
div.MsoNormal {
    margin-top: 0in;
    margin-right: 0in;
    margin-bottom: 8pt;
    margin-left: 0in;
    line-height: 107%;
    font-size: 11pt;
    font-family: "Calibri", sans-serif;
}
a:link,
span.MsoHyperlink {
    color: #0563c1;
    text-decoration: underline;
}
.MsoChpDefault {
    font-family: "Calibri", sans-serif;
}
.MsoPapDefault {
    margin-bottom: 8pt;
    line-height: 107%;
}
@page WordSection1 {
    size: 595.3pt 841.9pt;
    margin: 1in 1in 1in 1in;
}
div.WordSection1 {
    page: WordSection1;
}
/* List Definitions */
ol {
    margin-bottom: 0in;
}
ul {
    margin-bottom: 0in;
}
.privacy-policy-container {
}
</style>
